import {addBreadcrumb as registerSentryEvent} from '@sentry/react';
import {webPushService} from 'store/services/webPushService';

interface Config {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA',
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;

        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            installingWorker.postMessage('SKIP_WAITING');

            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        };
      };
    })
    .catch((error) => {
      registerSentryEvent({
        category: 'service-worker',
        message: `service worker instalation error ${error}`,
        level: error,
      });

      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  fetch(swUrl, {
    headers: {'Service-Worker': 'script'},
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      // TODO: setup a offline/online visual warning.
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function unregisterAllServiceWorkers(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }
}

export function clearServiceWorkersCache(): void {
  if ('caches' in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
}

function getBrowser() {
  if (browserInformation?.includes('Firefox')) {
    return 'FIREFOX';
  }

  if (
    browserInformation?.includes('Edg') ||
    browserInformation?.includes('Edge')
  ) {
    return 'EDGE';
  }

  if (
    browserInformation?.includes('OPR') ||
    browserInformation?.includes('Opera')
  ) {
    return 'OPERA';
  }

  if (browserInformation?.includes('Chrome')) {
    return 'CHROME';
  }

  return '';
}

const browserInformation = navigator?.userAgent;

async function subscribeToNotifications(
  registration: ServiceWorkerRegistration,
) {
  try {
    const notificationSubscription = await registration.pushManager.subscribe({
      applicationServerKey: process.env.REACT_APP_VAPID_KEY,
      userVisibleOnly: true,
    });

    const notificationSubscriptionJSON = notificationSubscription.toJSON();

    webPushService({
      p256dh: notificationSubscriptionJSON.keys?.p256dh,
      auth: notificationSubscriptionJSON.keys?.auth,
      registration_id: notificationSubscriptionJSON.endpoint,
      browser: getBrowser(),
    });
  } catch (err) {
    console.error('Error requesting permission', err);
  }
}

const applicationState = localStorage.getItem('letrusState');

const parsedApplicationState = applicationState
  ? JSON.parse(applicationState)
  : undefined;

const isAuthenticated =
  parsedApplicationState?.authentication?.data?.is_authenticated;

if (isAuthenticated) {
  navigator?.serviceWorker?.ready?.then(async (registration) => {
    // Check if notification permission has been granted
    const subscriptionState =
      await registration?.pushManager?.getSubscription();

    if (Notification.permission !== 'granted') {
      const permissionStatus = await Notification.requestPermission();

      if (permissionStatus === 'granted') {
        await subscribeToNotifications(registration);
      }

      // TODO: add code when permission is denied
    }

    if (Notification.permission === 'granted' && !subscriptionState) {
      await subscribeToNotifications(registration);
    }
  });
}
